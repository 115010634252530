import React, { Component } from 'react';
import { connect } from 'react-redux';

class PaymentTypes extends Component {
  state = {
    pad: true,
    check: this.props.countryCode === 'CA' ? false : true,
    card: this.props.enableCard ? this.props.countryCode === 'CA' ? this.props.enableCardCanada ? true : false : true : false
  }

  handleSelectItem = (e) => {
    let value = e.target.value;
    let newState = {};

    if (value === '1') {
      newState = {
        card: e.target.checked
      };
    } else if (value === '2') {
      newState = {
        check: e.target.checked
      };
     } else if (value === '3') {
      newState = {
        pad: e.target.checked
      };
    }

    this.setState(newState, () => { 
      this.props.onSelectedPaymentType(Object.assign({}, this.state));
    });

    // Enviar desde aqui un objeto con los items seleccionados.
    // Ejemplo: {card: true, check; false}
  }

  /**
   * Render the component view.
   */
  render() {
    return (
    (!this.props.hideRecptFilter && (
        <div className="form-group form-group--horizontal checkbox-inline">
          <span className="form-group__label">{this.props.whiteLabel.labels['Payment Type']}</span>

          <div className="flex-block">
            {
              (this.props.countryCode === 'CA' ? this.props.enableAch : false) &&
              (
                <label className="checkbox checkbox-inline__label">Bank Account (CA)
                  <input type="checkbox" value="3" checked={ this.state.pad }
                    onChange={ this.handleSelectItem } />
                  <span className="checkmark"></span>
                </label>
              )
            }

            {
              (this.props.countryCode === 'CA' ? false : this.props.enableAch) &&
              (
                <label className="checkbox checkbox-inline__label">{this.props.whiteLabel.labels['CHECK']}
                  <input type="checkbox" value="2" checked={ this.state.check }
                    onChange={ this.handleSelectItem } />
                  <span className="checkmark"></span>
                </label>
              )
            }

            {
              (this.props.enableCard ? this.props.countryCode === 'CA' ? this.props.enableCardCanada : true : false) &&
              (
                <label className="checkbox checkbox-inline__label">{this.props.whiteLabel.labels['CARD']}
                  <input type="checkbox" value="1" checked={ this.state.card }
                    onChange={ this.handleSelectItem } />
                  <span className="checkmark"></span>
                </label>
              )
            }
          </div>
        </div>
    )));
  }
}

const mapStateToProps = state => {
  let country = null;
  if (state.auth.userData){
    country = state.auth.userData.tblState ? state.auth.userData.tblState.countryCode : 'US';
  }
  return {
    enableAch: state.client.data.isAchMobile,
    enableCardCanada: state.client.data.hideCardIfCa,
    enableCard: state.client.data.cardEnable,
    countryCode: country,
    whiteLabel: state.whiteLabel,
    hideRecptFilter: state.client.data.hideRecptFilter
  }
}



export default connect(mapStateToProps)(PaymentTypes);
