import React, { Component } from 'react';
import { Application } from '../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { recoverPassword } from '../api/auth';
import { showLoading, hideLoading, showMessageBar } from '../actions/app';
import { changeEmail } from '../api/client';
import { INTERNAL_ERROR } from '../constants/errors';
import { rsaEncrypt } from '../helpers/auth';

// Import actions
import { userSignIn, socialSignIn, setMyInfoSocialNetwork } from '../actions/auth';
import { getWhiteLabelsAction } from '../actions/white-label';

//import components
import FacebookLogin from 'react-facebook-login';
import ForgotPassword from '../components/application/modals/forgot-password';
import ForgotPasswordForm from '../components/auth/forgot-password-form';
import Modal from '../components/application/modal';
import SignInForm from '../components/auth/signin-form';
import SocialButton from '../components/application/social-button'
import ChangeEmailModal from '../components/application/modals/change-email-modal';

/**
 * The FB ID and Google ID for social sign up.
 */
const FB_ID = process.env.REACT_APP_FB_ID;
const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;

class SignIn extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isModalOpen: false,
      isChangeEmailModalOpen: false,
      previousEmail: '',
      previousPass: '',
      emailEntered: '',
      logged: false,
      user: {},
      currentProvider: ''
    }

    this.nodes = {}
  }


  closeModal = () => {
    this.setState({
      isModalOpen: false,
      isChangeEmailModalOpen: false
    });
  }

  showForgotPasswordModal = () => {
    this.setState({
      isModalOpen: true
    })
  }

  showChangeEmailModal = (email, pass) => {
    this.setState({
      isChangeEmailModalOpen: true,
      previousEmail: email,
      previousPass: pass
    })
  }

  /**
   * Send the request thant send a link to recover the password.
   * 
   * @param values The form values.
   */
  handleForgotPassword = values => {
    this.props.showLoading();

    recoverPassword(values.contact)
      .then((response) => {
        let data = response.data;

        this.props.hideLoading();

        if (data.status === 1 && data.statusCode === 200) {
          this.closeModal();
          
          this.props.showMessageBar({
            status: 'success',
            text: data.message
          }); 
        } else {
          this.props.showMessageBar({
            status: 'error',
            text: data.message
          }); 
        }
      })
      .catch(() => {
        this.closeModal();
        this.props.hideLoading();
        this.props.showMessageBar({
          status: 'error',
          text: INTERNAL_ERROR
        });
      })
  }

  handleFacebookSignin = (response) => {
    let { status, id } = response;

    if (status === undefined && id) {
      this.props.socialSignIn({
        email: response.email,
        socialId: response.id,
        type: 1
      })
      .then((exists) => {
        if (!exists) {
          this.props.setMyInfoSocialNetwork({
            email: response.email ? response.email : '',
            firstName: response.first_name,
            lastName: response.last_name,
            socialId: response.id,
            type: 1
          });

          this.props.history.push('/signup');
        }
      })
      .catch(() => {
        Application.showInternalError();
      });
    }
  }

  handleLoginSignin = (user) => {
    let profileObj = user._profile;

    this.props.socialSignIn({
      email: profileObj.email,
      socialId: profileObj.id,
      type: 2
    })
    .then((exists) => {
      if (!exists) {
        this.props.setMyInfoSocialNetwork({
          email: profileObj.email ? profileObj.email : '',
          firstName: profileObj.firstName,
          lastName: profileObj.lastName,
          socialId: profileObj.id,
          type: 2
        });

        this.props.history.push('/signup');
      }
    })
    .catch(() => {
      Application.showInternalError();
    });;
  }

  onLoginFailure = (err) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
  }

  /**
   * Submit the sign in credentials for check the login.
   * 
   * @values
   */
  submitSignIn = values => {
    this.props.userSignIn(values)
      .then((result) => {
        // Give a chance to new borrower change his email before verifying it (Only the first time)
        if (result === 'borrower_email_not_verified_first_time' && this.props.verifyEmailForBpLogin === '1') {
          this.showChangeEmailModal(values.user, values.password);
        }
      })
      .catch(() => {});
  }

  /**
   * Send new request to change the email address for this borrower
   */
  changePreviousEmail = email => () => {

    if (email != '' && email != this.state.previousEmail) {
        this.setState({
          emailEntered: email
        });
    }

    let data = {
      previousEmail: this.state.previousEmail, 
      phoneno: "", 
      newEmail: email, 
      password: rsaEncrypt(this.state.previousPass)
    };
    this.props.showLoading();
    changeEmail(data)
      .then((result) => {
        if (result.data.status == 1) {
          this.props.showMessageBar({
            status: 'success',
            text: result.data.message
          }); 
        } else {
          this.props.showMessageBar({
            status: 'error',
            text: result.data.message
          }); 
        }
        this.props.hideLoading();
        this.closeModal();
      })
      .catch(() => {
        this.props.hideLoading();
      });
  }

  setNodeRef (provider, node) {
    if (node) {
      this.nodes[ provider ] = node
    }
  }

  /**
   * Render the social buttons to allow the user login with thems.
   */
  renderSocialButtons = () => {
    if (!this.props.client.socialLoginInd) {
      return null;
    }

    return (
      <div className="sign-form__social-buttons">
        <FacebookLogin
          appId={FB_ID}
          autoLoad={false}
          scope="public_profile,email"
          fields="first_name,last_name,middle_name,email"
          callback={this.handleFacebookSignin}
          textButton="Facebook"
          cssClass="btn-facebook"
          icon="fa-facebook" />

        <SocialButton
          provider='google'
          appId={GOOGLE_ID}
          onLoginSuccess={this.handleLoginSignin}
          onLoginFailure={this.onLoginFailure}
          getInstance={this.setNodeRef.bind(this, 'google')}
          key={'google'}>
          Google
        </SocialButton>
      </div>
    );
  }

  /**
   * Render the component view.
   */
  render() {
    let { client } = this.props;
    
    return (
      <div>
        <p className="sign-up-link__container">
          {client.languages.length > 1 && (
              <div className="half-responsive">
                <h4 className="settings-card__title">{this.props.whiteLabel.labels['Select Language']}</h4>
                <select className="form-group__input custom-select" value={this.props.language} name="language" onChange={this.props.onLanguageChange}>
                  {client.languages.map(language => (
                    <option key={language.id} value={language.code}>{language.name}</option>
                  ))}
                </select>
              </div>
            )}
        </p>
        <div className="sign-in-form__container">
          <div className="logo-img__container">
            <img className="logo-img" src={client.imageName} alt="Logo"/>
          </div>

          {/**
            ** Login with facebook and gmail.
            **/}
          {this.renderSocialButtons()}

          <SignInForm onSubmit={this.submitSignIn} whiteLabel={this.props.whiteLabel} />

          <p className="em-vertical-margin text-center">
            <span className="form-link gmedium-common" onClick={this.showForgotPasswordModal}>
            {this.props.whiteLabel.labels['Forgot Your Password']}
            </span>
          </p>

          <p className="sign-up-link__container">
            {this.props.whiteLabel.labels["Don't have an account"]}&nbsp;
            <Link to="/signup" className="sign-up-link">{this.props.whiteLabel.labels['Sign Up']}</Link>
          </p>

          <Modal 
            isOpen={this.state.isModalOpen}
            onClose={this.closeModal}>
            <ForgotPassword 
               whiteLabel={this.props.whiteLabel} form={<ForgotPasswordForm whiteLabel={this.props.whiteLabel} onSubmit={this.handleForgotPassword} />}
            />
          </Modal>
          <Modal 
            isOpen={this.state.isChangeEmailModalOpen}
            onClose={this.closeModal}>
            <ChangeEmailModal 
              email={this.state.previousEmail}
              whiteLabel={this.props.whiteLabel}
              onCancel={this.closeModal}
              onNext={this.changePreviousEmail} />
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    verifyEmailForBpLogin: state.client.data.verifyEmailForBpLogin,
    client: state.client.data,
    whiteLabel: state.whiteLabel
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    userSignIn, socialSignIn, setMyInfoSocialNetwork, showLoading, hideLoading, showMessageBar, getWhiteLabelsAction
  }, dispatch);
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));